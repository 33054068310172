.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.banner {
  color: white;
}

.player-name {
  background-color: teal;
  padding: 8px;
  border-radius: 4px;

}
