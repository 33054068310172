.mainBoard{
    width: 240px;
    margin: auto;
    height: 220px;
    padding: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: rgb(46, 44, 44); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 8px;
}

.box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    color: rgb(201, 194, 194);
    cursor: cell;
    font-family: sans-serif;
    font-size: 30px;
}
.box:hover{
    background-color: rgba(0, 0, 0, 0.4);
}
#box1, #box2, #box3, #box4, #box5, #box6{
    border-bottom: 2px solid rgba(201, 194, 194, 0.5);
}
#box3, #box6, #box9, #box2, #box5, #box8{
    border-left: 2px solid rgb(201, 194, 194, 0.5);
}